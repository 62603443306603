import React from 'react';
import Banner from './Banner';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { company } from '@components/config/company/Details';
import DoneIcon from '@mui/icons-material/Done';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Link from 'next/link';
const Booker = dynamic(() => import('main/bookerWrapper'), { ssr: false });

function Home() {
  return (
    <>
      <div className="home-topbg flex items-center">
        <div className="overlay w-full"></div>
        <div className="container relative z-10 py-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 text-white gap-10">
            <div className="w-full h-full">
              <Booker
                secretId={process.env.NEXT_PUBLIC_UID}
                qrId={process.env.NEXT_PUBLIC_QR_ID}
                iframe
              />
            </div>
            <div className="flex flex-col justify-center items-center h-full  order-2">
              <div>
                <div className="text-xl">
                  Your Reliable and Trustworthy Private Hire Taxis
                </div>
                <div className="mt-3 text-3xl lg:text-5xl font-medium">
                  Ace Budget, serving Fairweather Green, Allerton Sandy Lane,
                  and Surrounding Areas.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container grid md:grid-cols-2 py-20 ">
        <div className="md:px-10">
          <div className="text-5xl my-4 font-semibold">
            Simplify
            <span className="text-primary md:ml-2">
              <br />
              Your Corporate Travel Experience with Dependable Ease
            </span>
          </div>
          <div className="text-black/60">
            Experience the convenience of our reliable, stress-free corporate
            travel service. Designed with corporations in mind, our service
            offers trouble-free booking and billing, with priority status given
            to all your trips. Ensure your team travels in comfort with{' '}
            {company.name}.
          </div>
          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              Ensuring unbeatable convenience and priority status.
            </div>
          </div>
          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              Eliminate the hassle of coordinating individual bookings for your
              business.
            </div>
          </div>
          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              Access exclusive benefits such as dedicated account managers and
              personalized support tailored to your unique business needs.
            </div>
          </div>
          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              We offer an extensive fleet of vehicles to find the perfect ride
              for any occasion and elevate your corporate travel experience.
            </div>
          </div>

          <Link href={'/'}>
            <div className="text-white bg-primary text-center rounded-full inline-flex px-5 py-3 font-semibold mt-3 cursor-pointer hover:bg-secondary hover:text-white transition-all">
              BOOK NOW
              <ArrowRightAltIcon className="" />
            </div>
          </Link>
        </div>
        <div className="relative pt-20 md:pt-0">
          <Image
            src={'/assets/images/Business.png'}
            height={600}
            width={600}
            alt={'Business Taxi Image'}
          />
        </div>
      </div>
      <div className="container grid md:grid-cols-2 py-20 ">
        <div className="md:block hidden  pt-20 md:pt-0">
          <Image
            src={'/assets/images/FamilyTravel.png'}
            height={600}
            width={600}
            alt={'Family Travel Image'}
          />
        </div>
        <div className="md:px-10">
          <div className="md:text-5xl text-2xl my-4 font-semibold">
            Effortlessly
            <span className="text-primary md:ml-2">
              Navigate school runs and family travel with peace of mind.
            </span>
          </div>
          <div className="text-black/60">
            Safe and on-time school transportation is our top priority. Our
            dedicated local school-run service offers reliable and friendly
            drivers who prioritize safety and punctuality for your child. Rest
            easy knowing their journey is in good hands.
          </div>
          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              Ensuring child safety and punctuality is our top priority for our
              local school run service.
            </div>
          </div>
          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              Effortless transportation for school, activities, and events
              without stress.
            </div>
          </div>
          <Link href={'/'}>
            <div className="text-white bg-primary text-center rounded-full inline-flex px-5 py-3 font-semibold mt-3 cursor-pointer hover:bg-secondary hover:text-white transition-all">
              BOOK NOW
              <ArrowRightAltIcon className="" />
            </div>
          </Link>
        </div>

        <div className="md:hidden relative pt-20 md:pt-0">
          <Image
            src={'/assets/images/FamilyTravel.png'}
            height={600}
            width={600}
            alt={'Family Travel Image'}
          />
        </div>
      </div>
      <div className="container grid md:grid-cols-2 py-20 ">
        <div className="md:px-10">
          <div className="md:text-5xl text-2xl my-4 font-semibold">
            Experience
            <span className="text-primary md:ml-2">
              Hassle-free and punctual airport transfers
              <br />
              you can trust.
            </span>
          </div>
          <div className="text-black/60">
            Experience stress-free airport transfers to Bradford Airport, Leeds,
            and Manchester Airport with our reliable transportation service. Our
            dedicated drivers are committed to punctuality, ensuring you arrive
            on time without worries. Enjoy a seamless travel experience with
            competitive rates, whether for business or pleasure. Leave the
            logistics to us, book your ride today, and experience the
            convenience of our reliable airport transfer service. Safe travels!
          </div>
          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              Providing airport transfer services to Leeds Bradford Airport,
              Manchester Airport, and Liverpool John Lennon Airport.
            </div>
          </div>

          <div className="flex items-center gap-2 my-4">
            <div className="bg-primary rounded-full flex items-center justify-center p-1">
              <DoneIcon className="text-white text-lg" />
            </div>
            <div className="text-black/60">
              Competitive rates for high-quality service
            </div>
          </div>
          <div>
            Book your airport transfer today and enjoy the convenience of our
            reliable transportation service to and from the airport.
          </div>
          <Link href={'/'}>
            <div className="text-white bg-primary text-center rounded-full inline-flex px-5 py-3 font-semibold mt-3 cursor-pointer hover:bg-secondary hover:text-white transition-all">
              BOOK NOW
              <ArrowRightAltIcon className="" />
            </div>
          </Link>
        </div>
        <div className="relative pt-20 md:pt-0">
          <Image
            src={'/assets/images/AirportTransfersNew.png'}
            height={600}
            width={600}
            alt={'Airport TransfersNew Image'}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
